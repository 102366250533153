.drop-down-hours {
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop-down {
  position: relative;
  height: 35px;
  background-color: #00b3ce50;
  border-radius: 5px;
  width: 147px;
  text-align: center;
}
.drop-down-select{
    display: flex;
    justify-content: space-between;
}
.drop-down-select .text{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drop-down-select .drop-icon{
    display: flex;
    justify-content: center;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    align-items: center;
    cursor: pointer;
    width: 15%;
    height: 35px !important;
    background-color: var(--bg-button);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
}
.drop-down-content {
    width: 85%;
    height: 180px;
    font-size: 12px;
    overflow-y: scroll;
    background-color: #fff;
    box-shadow: 3px 1px 5px 3px rgba(0, 0, 0, .2);
    position: relative;
    z-index: 100000;
}
.drop-down-content::-webkit-scrollbar {
    width: 5px;
}
.drop-down-content::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: var(--bg-button);
    border-radius: 5px;
}
.drop-down-item{
    padding: 3px 10px;
    width: 100%;
    text-align: start;
}
.drop-down-item:hover{
    background-color: #00b3ce50;
    cursor: pointer;
}

.width100{
    width: 100% !important;
}
.disabled{
    background-color: rgba(211, 206, 206, 0.795);
    color: gray;
    overflow: hidden;
    border-radius: 5px;
}

.disabled .drop-icon{
    background-color: gray;
}
.card-login {
  width: 25% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: auto;
  margin-top: 10%;
}
.btn-register {
  width: 100% !important;
  margin-top: 15px;
  background-color: rgb(200, 214, 214);
  color: gray;
}
.terminos {
  margin-top: 10px;
  gap: 5px;
  color: var(--bg-button);
}
.terminos:hover {
  text-decoration: underline;
}
.active {
  color: white;
  background-color: var(--bg-button);
}
